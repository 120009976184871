import { Link } from "react-router-dom"
import { useEffect, useCallback, useState } from "react"
import axios from "axios";
const PaymentSuccess = () => {
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState('');
    const [reason, setReason] = useState('');
    const verifyPayment = useCallback(async () => {
        const token = localStorage.getItem('token');
        const reference = localStorage.getItem('reference')
        try {

            const res = await axios.post(`https://demo-api.mssnlagos.net/api/v1/opay/callback`, {reference: reference}, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log(res.data.data);
            setStatus(res.data.data.status);
            setReason(res.data.data.reason)
            setLoading(false);
        } catch (error) {
            console.log(error)
        }
    }, []);

    useEffect(() => {
        verifyPayment();
      }, []);
    return (
        <div className="h-[70vh] bg-white flex items-center justify-center">
            <div className={`flex flex-col items-center ${status === 'success' ? 'text-green-600' : 'text-red-600'} w-fit`}>
                { status === 'success' ? 
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 36 36"><path fill="currentColor" d="M18 2a16 16 0 1 0 16 16A16 16 0 0 0 18 2m0 30a14 14 0 1 1 14-14a14 14 0 0 1-14 14" className="clr-i-outline clr-i-outline-path-1"/><path fill="currentColor" d="M28 12.1a1 1 0 0 0-1.41 0l-11.1 11.05l-6-6A1 1 0 0 0 8 18.53L15.49 26L28 13.52a1 1 0 0 0 0-1.42" className="clr-i-outline clr-i-outline-path-2"/><path fill="none" d="M0 0h36v36H0z"/></svg>
                    : <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M12 17q.425 0 .713-.288Q13 16.425 13 16t-.287-.713Q12.425 15 12 15t-.712.287Q11 15.575 11 16t.288.712Q11.575 17 12 17Zm0-4q.425 0 .713-.288Q13 12.425 13 12V8q0-.425-.287-.713Q12.425 7 12 7t-.712.287Q11 7.575 11 8v4q0 .425.288.712q.287.288.712.288Zm0 9q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Zm0-2q3.35 0 5.675-2.325Q20 15.35 20 12q0-3.35-2.325-5.675Q15.35 4 12 4Q8.65 4 6.325 6.325Q4 8.65 4 12q0 3.35 2.325 5.675Q8.65 20 12 20Zm0-8Z"/></svg>
                }
                <p className="text-xl text-center font-medium mt-4">Payment {status === 'success' ? 'successful' : status}</p>
                <p className="text-center text-black mt-4 font-medium">{reason}</p>
                <Link to={'/'} className="px-3 py-2 rounded-md bg-[#0D5239] text-white font-medium mt-8 cursor-pointer hover:bg-[#106545] w-full">Go home</Link>
            </div>
            {
                loading &&
                <div className="w-screen h-screen fixed top-0 left-0 bg-[#0000004D] backdrop-blur flex items-center justify-center">
                    <div className="w-full p-6 border-t gap-4 bg-white w-[80vw] md:w-[500px] rounded-lg">
                        <p className="text-xl font-medium text-center">Loading... Please wait</p>
                    </div>
                </div>
            }
        </div>
    )
}

export default PaymentSuccess