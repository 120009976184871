import { useState, useCallback, useEffect, useRef } from "react";
import axios from "axios";

const BulkRegister = () => {
    const link = "https://docs.google.com/spreadsheets/d/1tZmvWjYlA9OKbLyOmy2fBbZxBWdaNY2yGXhS4i3V5mI/export?format=xlsx"
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(false)
    const fileInputRef = useRef(null);
    const [error, setError] = useState('');
    const [errorMessage, setErrorMessage] = useState([]);
    const [reference, setReference] = useState('');
    const [url, setUrl] = useState('')

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        uploadRegistration();
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };
    const api = axios.create({
        baseURL: 'https://demo-api.mssnlagos.net/api/v1/upload-excel',
      });
    const uploadRegistration = useCallback(async () => {
        if (!selectedFile) return;
        setLoading(true);
        const token = localStorage.getItem('token');
        try {
            const formData = new FormData();
            formData.append("file", selectedFile);

            const res = await axios.post(`https://demo-api.mssnlagos.net/api/v1/upload-excel`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
            });
            setUrl(res.data?.data?.checkout_url);
            setReference(res.data?.data?.reference);
            localStorage.setItem('reference', res.data.data.reference);
            setLoading(false);
        } catch (error) {
            setError(error.response.data.message)
            const messages = error.response.data.errors
            setErrorMessage(messages.flat())
            
        }
    }, [selectedFile]);
    const proceed = () => {
        window.open(url, '_blank')
        setReference('');
        setLoading(false)
    }

    api.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          if (error.response) {
            console.error(error.response);
          }
          return Promise.reject(error);
        }
      );

    useEffect(() => {
        if (selectedFile) {
            uploadRegistration();
        }
    }, [selectedFile, uploadRegistration]);

    return (
        <div className="py-8">
            <div className="border rounded-lg w-full md:w-[500px] mx-auto">
                <p className="text-lg font-medium px-6 py-4">Bulk registration</p>
                <div className="w-full flex flex-col items-center justify-center py-6 border-t gap-4">
                    <p className="px-4">Download the file below to input the details of the members you wish to register. Then use the upload button to upload the updated file.</p>
                    <div className="flex items-center justify-between w-full px-4">
                    <a href={link} download className="px-3 py-2 rounded-md bg-[#0D5239] text-white font-medium cursor-pointer hover:bg-[#106545]">Download file</a>
                    <div>
                    <input name="file" className="hidden" ref={fileInputRef} type="file" onChange={handleFileChange} />
                    <button onClick={handleButtonClick} className="px-3 py-2 rounded-md bg-[#0D5239] text-white font-medium cursor-pointer hover:bg-[#106545]">Upload file</button>
                    </div>
                    </div>
                </div>
            </div>
       {(loading || reference !== '') && <div className="w-screen h-screen fixed top-0 left-0 bg-[#0000004D] backdrop-blur flex items-center justify-center">
            
            <div className="w-full p-6 border-t gap-4 bg-white w-[80vw] md:w-[500px] rounded-lg">
                { reference !== '' && <>
                <div className="flex items-center gap-3 text-green-600 justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 36 36"><path fill="currentColor" d="M18 2a16 16 0 1 0 16 16A16 16 0 0 0 18 2m0 30a14 14 0 1 1 14-14a14 14 0 0 1-14 14" className="clr-i-outline clr-i-outline-path-1"/><path fill="currentColor" d="M28 12.1a1 1 0 0 0-1.41 0l-11.1 11.05l-6-6A1 1 0 0 0 8 18.53L15.49 26L28 13.52a1 1 0 0 0 0-1.42" className="clr-i-outline clr-i-outline-path-2"/><path fill="none" d="M0 0h36v36H0z"/></svg>
                    <p className="text-center text-lg font-medium">Success</p> 
                </div>
                <p  className="font-medium my-4">Reference number: {reference}</p>
                <div className="flex items-center justify-between w-full">
                    <button onClick={proceed} className="px-3 py-2 rounded-md bg-[#0D5239] text-white font-medium hover:bg-[#106545]">Proceed to pay</button>
                    <button onClick={() => {setReference(''); setSelectedFile(null)}} className="px-3 py-2 rounded-md bg-[#0D5239] text-white font-medium ml-auto mr-0 w-fit hover:bg-[#106545]">Close</button>
                </div>
                </>}
                    { loading && <p className="text-xl font-medium text-center">Loading... Please wait</p> }
                    {error !== '' && <div> 
                        <div className="flex items-center gap-3 text-red-600 justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M12 17q.425 0 .713-.288Q13 16.425 13 16t-.287-.713Q12.425 15 12 15t-.712.287Q11 15.575 11 16t.288.712Q11.575 17 12 17Zm0-4q.425 0 .713-.288Q13 12.425 13 12V8q0-.425-.287-.713Q12.425 7 12 7t-.712.287Q11 7.575 11 8v4q0 .425.288.712q.287.288.712.288Zm0 9q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Zm0-2q3.35 0 5.675-2.325Q20 15.35 20 12q0-3.35-2.325-5.675Q15.35 4 12 4Q8.65 4 6.325 6.325Q4 8.65 4 12q0 3.35 2.325 5.675Q8.65 20 12 20Zm0-8Z"/></svg>
                             <p className="text-red-600 text-center text-lg font-medium">{error}</p> 
                        </div>
                        <div className="overflow-y-auto max-h-[400px] mt-4">{
                            errorMessage.map((item) => <p className="text-sm font-medium">{item}</p>)
                        }</div>
                    <button onClick={() => {setLoading(false); setSelectedFile(null)}} className="px-3 py-2 rounded-md bg-[#0D5239] text-white font-medium mt-8 cursor-pointer hover:bg-[#106545]">Close</button>
                    </div>}
                    </div>
                </div>}

        </div>
    )
}

export default BulkRegister