import {useState} from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import BGD from "../assets/images/theme.jpg";
import LOGO from "../assets/images/Logo.png";
import Footer from "../shared_components/footer.jsx";

const SignIn = () => {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const navigate =  useNavigate()

    async function handleSignUp (e) {
      try {
        const response = await axios.post('https://demo-api.surecoders.com/api/auth/register', {
          name: name, email: email, password: password, password_confirmation: password
        })
          console.log(response)
         // navigate('/') 
      } catch (error) {
        console.log(error)
      }
    }

    function handleOnSubmit(e) {
      e.preventDefault();
    }
  return (
    <div className="main relative">
      <div
        className="h-[400px]"
        style={{
          backgroundImage: `url(${BGD})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat", 
        }}
      >
        <img className="pt-6 pl-24 mb-14" src={LOGO} alt="" srcSet="" />
        
        </div>
        <div className="relative mx-auto bottom-32 p-10 form grid place-items-center">
        <form className=" bottom-0 w-full lg:w-[900px] bg-white p-10 shadow rounded-lg" onSubmit={handleOnSubmit}>
        <div className="flex flex-wrap -mx-3 mb-4">
        <div className='w-full'>
          <h1 className="text-center w-full mb-4 text-green-800 text-2xl font-bold">
            AREA COUNCIL PORTAL
          </h1>
        </div>
            <div className="w-full px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="name"
              >
                NAME
              </label>
              <input onChange= {(e) => setName(e.target.value)} value={name}
                className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:border-[#0D5239]"
                type="text"
                placeholder="Enter name"
              />
              
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-4">
            <div className="w-full px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="email"
              >
                EMAIL
              </label>
              <input onChange= {(e) => setEmail(e.target.value)} value={email}
                className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-[#0D5239]"
                type="email"
                placeholder="Enter email"
              />
              {/* <p className="text-red-500 text-xs italic">
                Please fill out this field.
              </p> */}
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="password"
              >
                Password
              </label>
              <input onChange= {(e) => setPassword(e.target.value)} value={password}
                className="appearance-none block w-full bg-white-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-[#0D5239]"
                id="password"
                type="password"
                placeholder="Enter password"
              />
            </div>
          </div>
          {
            name && email && password ? 
            <div className="flex justify-center"> 
              <button
                className="shadow bg-[#0D5239] hover:bg-green-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                type="submit" onClick={() => handleSignUp()}
              >
                Register
              </button>
            </div> : (
            <div className="flex justify-center"> 
              <button
                className="shadow bg-[#0D5239] hover:bg-green-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                type="button"
              >
                Register
              </button>
              </div>
             )
          } 
        </form>
        </div>
      <Footer className="absolute bottom-0" />
    </div>
  );
};

export default SignIn;

