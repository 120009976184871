import { Routes, Route } from "react-router-dom";
import './App.css';
import {Toaster} from 'react-hot-toast'
import PageLayout from './shared_components/PageLayout.jsx';
import SignIn from './Auth/SignIn'
import SignUp from './Auth/SignUp'
import ProtectedRoute from "./Auth/ProtectedRoute.js";

function App() {
  return (

    <>
      <Routes>
          <Route  path="/signin" element={<SignIn />}/>
          <Route path="*" element={<ProtectedRoute><PageLayout/></ProtectedRoute>}/>
          <Route path="/SignUp"  element = {<SignUp/>} />
      </Routes>
      <Toaster/>
    </>
  );
}

export default App;
