import { Routes, Route, Link, useLocation } from "react-router-dom";
import Footer from './footer.jsx';
import Logo from '../assets/images/Logo.png';
import Register from './register.jsx'
import PaymentSuccess from "./PaymentSuccess.jsx";
import Home from './Home.jsx'

const PageLayout = () => {
    const areaCouncil = localStorage.getItem("areaCouncil");
    const location = useLocation()
    const LogOut = async () => {
        localStorage.clear()
        window.location.pathname = "/signin"
    }

    return (
        <div className="w-full space-y-8 min-h-screen relative">
            <div className="py-4 px-6 lg:px-16 w-full bg-black flex items-center justify-between">
                <img className="w-[50px] md:w-[70px] lg:w-auto" src={Logo} alt="" />
                <div className="flex space-x-4 items-center">
                <nav className="flex space-x-6 md:mr-8">
                    <Link to="/" className={`${location.pathname === '/' && 'text-[#0D5239]'} text-white text-sm md:text-lg font-medium hover:text-gray-400`}>Home</Link>
                    <Link to="/register" className={`${location.pathname === '/register' && 'text-[#0D5239]'} text-white text-sm md:text-lg font-medium hover:text-gray-400`}>Registration</Link>
                </nav>
                    <div className="flex items-center space-x-4">
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="40" height="40" rx="20" fill="#F9FAFB"/>
                            <path d="M28.3333 29.375V27.2917C28.3333 26.1866 27.8943 25.1268 27.1129 24.3454C26.3315 23.564 25.2717 23.125 24.1667 23.125H15.8333C14.7283 23.125 13.6684 23.564 12.887 24.3454C12.1056 25.1268 11.6667 26.1866 11.6667 27.2917V29.375M24.1667 14.7917C24.1667 17.0929 22.3012 18.9583 20 18.9583C17.6988 18.9583 15.8333 17.0929 15.8333 14.7917C15.8333 12.4905 17.6988 10.625 20 10.625C22.3012 10.625 24.1667 12.4905 24.1667 14.7917Z" stroke="#475467" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <p className="text-white text-lg hidden md:block">{areaCouncil} Admin</p>
                    </div>
                    <p className="px-4 text-white font-bold hidden md:block">|</p>
                    <button className="text-[#F38080] text-sm md:text-lg font-medium" onClick={() => LogOut()}>Logout</button>
                </div>
            </div>
            <Routes>
                <Route path="*" element={<Home />}/>
                <Route path="register" element={<Register/>}/>
                <Route path="/payment-success" element={<PaymentSuccess/>} />
            </Routes>
            <Footer />
        </div>
    )
}

export default PageLayout;