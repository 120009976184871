import { useState } from "react";
import BulkRegister from "./BulkRegister";
const Register = () => {
    const [tab, setTab] = useState('single');
    
    return (
        <div className="xl:px-32 lg:px-16 md:px-8 px-4">
            <div className="flex text-black items-center border-b w-full">
                <p onClick={() => setTab('single')} className={`px-3 py-2 cursor-pointer ${tab === 'single' ? 'border-b-2 border-[#0D5239]' : ''}`}>Single Registration</p>
                <p onClick={() => setTab('bulk')} className={`px-3 py-2 ${tab === 'bulk' ? 'border-b-2 border-[#0D5239]' : ''} cursor-pointer`}>Bulk Registration</p>
            </div>
            {
                tab === 'single' ? 
                    <div className="py-8">
                        <div className="border rounded-lg w-full md:w-[500px] mx-auto">
                            <p className="text-lg font-medium px-6 py-4">Camp registration</p>
                            <div className="w-full flex items-center justify-center py-8 border-t gap-4">
                                <a href="https://camp.mssnlagos.net/newmember" rel="noreferrer" target="_blank" className="px-3 py-2 rounded-md bg-[#0D5239] text-white font-medium cursor-pointer hover:bg-[#106545]">New Member</a>
                                <a href="https://camp.mssnlagos.net/existingmember" rel="noreferrer" target="_blank" className="px-3 py-2 rounded-md bg-[#0D5239] text-white font-medium cursor-pointer hover:bg-[#106545]">Existing Member</a>
                            </div>
                        </div>
                    </div>
                    : <BulkRegister />
            }
        </div>

    )
}

export default Register