import DelegateAnalytics from './delegateAnalytics.jsx';
import DelegateList from './delegateList.jsx';
import Delegates from '../layouts/skeletons/Delegates.jsx';
import axios from 'axios';
import React, {useState, useEffect, useCallback} from 'react';

const Home = () => {
    const areaCouncil = localStorage.getItem("areaCouncil");
    const [allDelegates, setAllDelegates] = useState(null);

    // get all Delegates from the api
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getAllDelegates = useCallback(async () => {
        const token = localStorage.getItem('token');
        try {
          const res = await axios({
            method: 'GET',
            url: `https://demo-api.mssnlagos.net/api/v1/alldelegates`,
            headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          });
          setAllDelegates(res?.data?.data);
        } catch (error) {}
      }, []);

    useEffect(() => {
        getAllDelegates();
      }, [getAllDelegates]);
    return (
        <div className="xl:px-32 lg:px-16 md:px-8 px-4">
            {!allDelegates ? <Delegates /> : 
            <>
                <DelegateAnalytics allDelegates={allDelegates} />
                <DelegateList areaCouncil={areaCouncil} allDelegates={allDelegates} />
            </> 
            }
            
        </div>
    )
}

export default Home;