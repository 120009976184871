import axios from "axios";
import { useState, useEffect, useCallback, useRef } from "react";
import toast from "react-hot-toast";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

const inputArray = [
  "Adeniran Ogunsanya College of Education (AOCOED)",
  "Ansarudeen College of Education (ADCOED) Isolo",
  "Federal College of Education Akoka FCE(T)",
  "Lagos State Polytechnic (Laspotech IKORODU)",
  "Lagos State Polytechnic (Laspotech ISOLO)",
  "Lagos State Polytechnic (Laspotech SURULERE)",
  "Lagos State School of Health Technology (LASCOHET)",
  "LASU College of Medicine, Ikeja (LASUCOM)",
  "Lagos State University (LASU) Epe",
  "Lagos State University (LASU) Ojo",
  "College of Medicine, Idi-Araba (CMUL/LUTH)",
  "Michael Otedola College of Primary Education, Epe (MOCPED)",
  "Federal College of Fisheries and Marine Technology (FEDFISH) (OCEANOGRAPHY)",
  "St. Augustine College of Education [Project Time], Akoka (SACOED)",
  "University of Lagos, Akoka (UNILAG)",
  "Yaba College of Technology, Yaba (YABATECH)",
  "Lagos State University Anthony Campus",
  "Federal School of Occupational Therapy, Oshodi (FSOT)",
  "Grace Polytechnic, Surulere (Grace)",
  "Lagos City Polytechnic, Ikeja (Lagos City)",
  "Lagos State University, Surulere (AASOC) (LASUSOC)",
  "Nigeria Institute of Journalism (NIJ)",
  "Lagos State University of Education (LASUED) Ijanikin",
  "Lagos State University of Education (LASUED) Epe",
];

const branchOptions = inputArray.map((item) => ({ label: item, value: item }));

console.log(branchOptions);

const DelegateList = ({ allDelegates, areaCouncil }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [categorizedDelegates, setCategorizedDelegates] =
    useState(allDelegates);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedPinCategory, setSelectedPinCategory] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [delegatesPerPage, setDelegatesPerPage] = useState(12);
  const inputRef = useRef();
  const [selectedDelegateIds, setSelectedDelegateIds] = useState([]);
  const [selectedBulkAction, setSelectedBulkAction] = useState("");

  const areaCouncilName = areaCouncil.toUpperCase();

  const isBranchUser = localStorage.getItem("areaCouncil") === "All branches";

  const handleCategoryClick = (category) => {
    const filteredData = allDelegates.filter(
      (item) => item.category === category
    );

    setCategorizedDelegates(filteredData);
    setSelectedCategory(category);
    setCurrentPage(1); // Reset to the first page when a category is clicked
  };

  const handlePaginationViewClick = (number) => {
    setDelegatesPerPage(number);
  };

  const filterDelegates = useCallback(
    (category, gender, value, pinCategory, status, branch) => {
      let filteredDelegates = allDelegates;

      if (category.length > 0) {
        filteredDelegates = filteredDelegates.filter(
          (item) => item.category.toLowerCase() === category.toLowerCase()
        );
      }

      if (gender.length > 0) {
        filteredDelegates = filteredDelegates.filter(
          (item) => item.gender.toLowerCase() === gender.toLowerCase()
        );
      }

      if (pinCategory?.length > 0) {
        filteredDelegates = filteredDelegates.filter(
          (item) => item.pin_cat.toLowerCase() === pinCategory.toLowerCase()
        );
      }

      if (status) {
        filteredDelegates = filteredDelegates.filter((item) => {
          if (status === "admitted") {
            return item.status === 1;
          } else {
            return item.status === 0;
          }
        });
      }

      if (branch.length > 0 && selectedBranch) {
        filteredDelegates = filteredDelegates.filter((item) =>
          item.branch?.includes(branch)
        );
      }

      if (value.trim() !== "") {
        const lowerCaseValue = value.toLowerCase();

        filteredDelegates = filteredDelegates.filter((delegate) => {
          return (
            (delegate.first_name &&
              delegate.first_name.toLowerCase().includes(lowerCaseValue)) ||
            (delegate.last_name &&
              delegate.last_name.toLowerCase().includes(lowerCaseValue)) ||
            (delegate.mssn_id &&
              delegate.mssn_id.toLowerCase().includes(lowerCaseValue))
          );
        });
      }

      setCategorizedDelegates(filteredDelegates);
      setCurrentPage(1);
    },
    [allDelegates, selectedBranch]
  );

  const handleSearchChange = (value) => {
    setSearchTerm(value);
    filterDelegates(
      selectedCategory,
      selectedGender,
      searchTerm,
      selectedPinCategory,
      selectedStatus,
      selectedBranch
    );
  };

  useEffect(() => {
    // Update categorizedDelegates whenever experts or selectedCategory changes
    filterDelegates(
      selectedCategory,
      selectedGender,
      searchTerm,
      selectedPinCategory,
      selectedStatus,
      selectedBranch
    );
  }, [
    allDelegates,
    selectedCategory,
    selectedGender,
    searchTerm,
    selectedPinCategory,
    selectedStatus,
    selectedBranch,
    filterDelegates,
  ]);

  // Pagination
  const indexOfLastDelegate = currentPage * delegatesPerPage;
  const indexOfFirstDelegate = indexOfLastDelegate - delegatesPerPage;
  const currentDelegates = categorizedDelegates.slice(
    indexOfFirstDelegate,
    indexOfLastDelegate
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Function to toggle the selection of all delegates
  const handleSelectAll = () => {
    if (selectedDelegateIds.length === currentDelegates.length) {
      setSelectedDelegateIds([]);
    } else {
      const allIdsOnPage = currentDelegates.map((delegate) => delegate.mssn_id);
      setSelectedDelegateIds(allIdsOnPage);
    }
  };

  // Function to handle individual delegate selection
  const handleDelegateSelect = (id) => {
    // Check if the ID is already selected
    const isAlreadySelected = selectedDelegateIds.includes(id);

    if (isAlreadySelected) {
      // If selected, remove it from the array
      setSelectedDelegateIds((prevIds) =>
        prevIds.filter((prevId) => prevId !== id)
      );
    } else {
      // If not selected, add it to the array
      setSelectedDelegateIds((prevIds) => {
        // Ensure that the ID is unique in the array
        if (!prevIds.includes(id)) {
          return [...prevIds, id];
        }
        return prevIds;
      });
    }
  };

  const handleSendDelegateToRegistry = async () => {
    const token = localStorage.getItem("token");
    const toastId = toast.loading("Sending...");
    try {
      const res = await axios({
        method: "POST",
        url: `https://demo-api.mssnlagos.net/api/v1/sendtoregistry?admitted[]=${selectedDelegateIds}`,
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      toast.remove(toastId);
      toast.success(res.data.message, {
        id: "clipboard",
      });
    } catch (error) {
      toast.remove(toastId);
      toast.error(error.response.data.message);
    }

    // console.log(selectedDelegateIds);
    // console.log(selected);
  };

  // Export Deleaget to PDF
  const handleExportPDF = () => {
    // Check if at least one delegate is selected

    // Create a new jsPDF instance
    const pdf = new jsPDF();

    // Add a logo to the PDF (replace 'your_logo_url' with the actual URL of your logo)
    const logoUrl =
      "http://localhost:3000/static/media/Logo.f91103e79c435708742c.png";
    pdf.addImage(logoUrl, "JPEG", 20, 10, 30, 30); // Further reduced logo size

    // Add the additional title information
    pdf.setFontSize(10);

    // Line 1
    // Create a container for the text lines
    const textContainerX = 105; // Adjust the horizontal position for space away from the logo
    const textContainerY = 10; // Adjust the vertical position for space between logo and text
    const textContainerHeight = 70;

    // Center the container
    const centerTextContainer = (containerHeight) => {
      const textY =
        textContainerY + (textContainerHeight - containerHeight) / 2;
      return textY;
    };

    // Calculate the height of the text lines
    const totalTextHeight = 55;

    // Calculate the container height
    const containerHeight = totalTextHeight;

    // Line 1
    pdf.setFont("helvetica", "bold");
    pdf.text(
      "MUSLIM STUDENT'S OF SOCIETY OF NIGERIA",
      textContainerX,
      centerTextContainer(containerHeight),
      { align: "center" }
    );

    // Line 2
    pdf.text(
      "LAGOS STATE AREA UNIT",
      textContainerX,
      centerTextContainer(containerHeight) + 5,
      { align: "center" }
    );

    // Line 3
    pdf.text(
      "113TH ISLAMIC VACATION COURSE",
      textContainerX,
      centerTextContainer(containerHeight) + 10,
      { align: "center" }
    );

    // Line 4
    pdf.text(
      "REGISTRY DEPARTMENT",
      textContainerX,
      centerTextContainer(containerHeight) + 15,
      { align: "center" }
    );

    // Add extra space
    pdf.text("", textContainerX, centerTextContainer(containerHeight) + 15);

    // Line 5
    pdf.text(
      "AREA COUNCIL: " + areaCouncilName,
      textContainerX,
      centerTextContainer(containerHeight) + 30,
      { align: "center" }
    );

    // Extract data for selected delegates
    const selectedDelegatesData = selectedDelegateIds.map((id, index) => {
      const selectedDelegate = allDelegates.find(
        (delegate) => delegate.mssn_id === id
      );
      if (selectedDelegate) {
        return [
          index + 1, // Serial number (S/N)
          selectedDelegate.mssn_id,
          `${selectedDelegate.first_name} ${selectedDelegate.last_name}`,
          selectedDelegate.gender.toUpperCase(),
          selectedDelegate.pin,
          selectedDelegate.pin_cat,
          selectedDelegate.phone,
          selectedDelegate.email,
        ];
      }
      return [];
    });

    // Add autoTable to the PDF
    autoTable(pdf, {
      head: [["S/N", "MSSN ID", "Full Name", "GENDER", "PIN", "CATEGORY", "PHONE", "EMAIL"]],
      body: selectedDelegatesData,
      startY: 60, // Adjust the starting position after the title
      theme: "grid", // Use the 'grid' theme for the table body
      styles: { font: "helvetica", fontSize: 8 },
    });

    // Save the PDF
    pdf.save(`${areaCouncilName.toLowerCase()}_delegates_list.pdf`);
  };

  // Bulk Action Request
  const handleBulkAction = (type) => {
    if (selectedDelegateIds.length === 0) {
      // No delegates selected, show a message or take appropriate action
      toast.error("Please select at least one delegate before Bulk Action");
      return;
    }

    if (type.lenght === 0) {
      toast.error("Please Pick a Bulk action");
      return;
    }

    setSelectedBulkAction("");

    type === "exportPDF" ? handleExportPDF() : handleSendDelegateToRegistry();
  };

  return (
    <div className="w-full mt-8">
      <div>
        <div className="mb-4">
          <p className="font-bold">Delegate List</p>
        </div>

        <div className="flex items-center justify-between mb-6">
          <form className="">
            {/* <label htmlFor="simple-search" className="sr-only">Search</label> */}
            <div className="flex items-center pr-3 gap-2 border border-[#D7DCE0] rounded-lg">
              <input
                type="text"
                id="simple-search"
                ref={inputRef}
                onChange={(event) => handleSearchChange(event.target.value)}
                className="relative text-gray-900 text-xs md:text-sm w-48 rounded-lg md:w-full border-none focus-[#D7DCE0] py-2.5"
                placeholder="Search by MSSNID ,First Name, Last Name"
                required
              />
              <button type="button" aria-label="Search" className="">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
          </form>
          {/* Bulk Action */}
          <div className="text-sm md:text-base">
            <select
              name=""
              id=""
              className="w-auto text-xs rounded-lg"
              value={selectedBulkAction}
              onChange={(e) => handleBulkAction(e.target.value)}
            >
              <option value="">Bulk Action</option>
              <option value="sendToRegistry">Send To Registry</option>
              <option value="exportPDF">Download PDF</option>
            </select>
          </div>
        </div>

        <div className="">
          <h5 className="text-sm md:text-base font-semibold mb-2">
            Filter Options
          </h5>
          <div className=" mb-6 overflow-x-auto scrollbar-hide p-4">
            <div className="flex space-x-3 md:space-y-0 md:space-x-3 md:flex-row">
              <div className="block">
                <select
                  onChange={(e) => {
                    setSelectedPinCategory(e.target.value);
                    filterDelegates(
                      selectedCategory,
                      e.target.value,
                      searchTerm,
                      selectedPinCategory,
                      selectedStatus,
                      selectedBranch
                    );
                  }}
                  name=""
                  id=""
                  className="w-auto text-xs rounded-lg focus:outline-none"
                >
                  <option value="">All Categories</option>
                  <option value="SECONDARY">Secondary </option>
                  <option value="UNDERGRADUATE">Undergraduate</option>
                  <option value="OTHERS">Others</option>
                </select>
              </div>
              <div className="block">
                <select
                  onChange={(e) => {
                    setSelectedStatus(e.target.value);
                    filterDelegates(
                      selectedCategory,
                      e.target.value,
                      searchTerm,
                      selectedPinCategory,
                      selectedStatus,
                      selectedBranch
                    );
                  }}
                  name=""
                  id=""
                  className="w-auto text-xs rounded-lg focus:outline-none"
                >
                  <option value="">All Status</option>
                  <option value="not_admitted">Not Admitted</option>
                  <option value="admitted">Admitted</option>
                </select>
              </div>
              <div className="hidden md:block">
                <select
                  onChange={(e) => handleCategoryClick(e.target.value)}
                  name=""
                  id=""
                  className="w-auto text-xs rounded-lg focus:outline-none"
                >
                  <option value="">All Class</option>
                  <option value="DAWAH CLASS">DAWAH</option>
                  <option value="UPPER CLASS">UPPER</option>
                  <option value="INTERMEDIATE CLASS">INTERMEDIATE</option>
                  <option value="LOWER CLASS">LOWER</option>
                </select>
              </div>
              <div className="hidden md:block">
                <select
                  name=""
                  id=""
                  className="w-auto text-xs rounded-lg focus:none"
                  onChange={(e) => {
                    setSelectedGender(e.target.value);
                    filterDelegates(
                      selectedCategory,
                      e.target.value,
                      searchTerm,
                      selectedPinCategory,
                      selectedStatus,
                      selectedBranch
                    );
                  }}
                >
                  <option value="">All Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
              <div className="block">
                <select
                  name=""
                  onChange={(e) => handlePaginationViewClick(e.target.value)}
                  id=""
                  className="w-auto text-xs rounded-lg focus:none"
                >
                  <option value="12">Show 12</option>
                  <option value="50">Show 50</option>
                  <option value="100">Show 100</option>
                  <option value="250">Show 250</option>
                  <option value="500">Show 500</option>
                  <option value="10000">Show All</option>
                </select>
              </div>
              {isBranchUser && (
                <div className="block">
                  <select
                    onChange={(e) => {
                      setSelectedBranch(e.target.value);
                      filterDelegates(
                        selectedCategory,
                        e.target.value,
                        searchTerm,
                        selectedPinCategory,
                        selectedStatus,
                        selectedBranch
                      );
                    }}
                    name=""
                    id=""
                    className="w-28 text-xs rounded-lg focus:outline-none"
                  >
                    <option value="">All branch</option>
                    {branchOptions.map((option) => (
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-x-auto relative border border-[D7DCE0] p-6 rounded-lg mb-8">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 whitespace-nowrap">
          <thead className="text-xs text-gray-400 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3 space-x-4">
                <input
                  id="select-all-checkbox"
                  type="checkbox"
                  value=""
                  onChange={handleSelectAll}
                  checked={
                    selectedDelegateIds.length === currentDelegates.length
                  }
                  className="w-4 h-4 text-green-500 bg-gray-100 border-gray-300 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <span>MSSN ID</span>
              </th>
              <th scope="col" className="px-6 py-3">
                Full Name
              </th>
              <th scope="col" className="px-6 py-3">
                Gender
              </th>
              {
                // Show the class column only if the user is branch user
                isBranchUser && (
                  <th scope="col" className="px-6 py-3">
                    Branch
                  </th>
                )
              }
              <th scope="col" className="px-6 py-3">
                Class
              </th>
              <th scope="col" className="px-6 py-3">
                Category
              </th>
              <th scope="col" className="px-6 py-3">
                Status
              </th>
              <th scope="col" className="px-6 py-3">
                Payment reference
              </th>
              <th scope="col" className="px-6 py-3">
                Payment status
              </th>
            </tr>
          </thead>
          <tbody>
            {currentDelegates && currentDelegates.length > 0 ? (
              currentDelegates.map((user) => (
                <tr
                  key={user.id}
                  onClick={() => handleDelegateSelect(user.mssn_id)}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <th scope="col" className="px-6 py-3 space-x-4">
                    <input
                      id={`delegate-checkbox-${user.id}`}
                      type="checkbox"
                      className="w-4 h-4 text-green-500 bg-gray-100 border-gray-300 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      value={user.mssn_id}
                      checked={selectedDelegateIds.includes(user.mssn_id)}
                      onChange={() => handleDelegateSelect(user.mssn_id)}
                    />
                    <span className="">{user.mssn_id}</span>
                  </th>
                  <td className="px-6 py-4">
                    {user.first_name + " " + user.last_name}
                  </td>
                  <td className="px-6 py-4">{user.gender.toUpperCase()}</td>
                  {
                    // Show the class column only if the user is branch user
                    isBranchUser && <td className="px-6 py-4">{user.branch}</td>
                  }
                  <td className="px-6 py-4">{user.category}</td>
                  <td className="px-6 py-4">{user.pin_cat}</td>
                  <td className="px-6 py-4">
                    <span
                      className={`relative ${
                        user.status === 1 ? "text-green-400" : "text-red-400"
                      } `}
                    >
                      {user.status === 1 ? "Admitted" : "Not Admitted"}
                    </span>
                  </td>
                  <td className="px-6 py-4">{user.payment_reference}</td>
                  <td className="px-6 py-4">{user.payment_status}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="w-full pt-4 mx-auto text-center" colSpan={4}>
                  No records found
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <div className="flex flex-col items-center justify-between w-full gap-3 mt-4 pageination lg:flex-row">
          <div className="text-sm leftPagi">
            <p>
              Showing <b>{indexOfFirstDelegate + 1}</b> -{" "}
              <b>
                {Math.min(indexOfLastDelegate, categorizedDelegates.length)}
              </b>{" "}
              from <b>{categorizedDelegates.length}</b> delegates
            </p>
          </div>

          <div className="rightPagi ">
            <div className="flex items-center gap-3 px-5 pagi a-auto">
              <span className="backButton">
                <button
                  className="flex items-center"
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.72109 18.3938C9.89922 18.3938 10.0773 18.3282 10.218 18.1876C10.4898 17.9157 10.4898 17.4657 10.218 17.1938L5.02422 12L10.218 6.8063C10.4898 6.53442 10.4898 6.08442 10.218 5.81255C9.94609 5.54067 9.49609 5.54067 9.22422 5.81255L3.53359 11.5032C3.26172 11.775 3.26172 12.225 3.53359 12.4969L9.22422 18.1876C9.36484 18.3282 9.54297 18.3938 9.72109 18.3938Z"
                      fill="#0F172A"
                    />
                    <path
                      d="M4.19063 12.7031H19.9688C20.3531 12.7031 20.6719 12.3844 20.6719 12C20.6719 11.6156 20.3531 11.2969 19.9688 11.2969H4.19063C3.80625 11.2969 3.4875 11.6156 3.4875 12C3.4875 12.3844 3.80625 12.7031 4.19063 12.7031Z"
                      fill="#0F172A"
                    />
                  </svg>
                </button>
              </span>

              <ul className="flex items-center gap-5 text-xs numbers">
                {Array.from({
                  length: Math.ceil(
                    categorizedDelegates.length / delegatesPerPage
                  ),
                }).map((_, index) => {
                  const page = index + 1;

                  // Display only the adjacent pages and the current page
                  if (
                    page === currentPage ||
                    page === currentPage - 1 ||
                    page === currentPage + 1 ||
                    page === 1 ||
                    page ===
                      Math.ceil(categorizedDelegates.length / delegatesPerPage)
                  ) {
                    return (
                      <li
                        key={page}
                        onClick={() => paginate(page)}
                        className={`${
                          currentPage === page
                            ? "active bg-[#0D5239] text-white"
                            : "hover:bg-[#0D5239] hover:text-white transition duration-300 cursor-pointer ease-in-out"
                        } py-1 px-2 rounded-lg`}
                      >
                        {page}
                      </li>
                    );
                  } else if (
                    page === currentPage - 2 ||
                    page === currentPage + 2
                  ) {
                    return <li key={page}>...</li>;
                  }

                  return null;
                })}
              </ul>

              <span className="nextButton">
                <button
                  className="flex items-center"
                  onClick={() => paginate(currentPage + 1)}
                  disabled={indexOfLastDelegate >= categorizedDelegates.length}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.2789 18.3938C14.1008 18.3938 13.9227 18.3282 13.782 18.1875C13.5102 17.9157 13.5102 17.4657 13.782 17.1938L18.9758 12L13.782 6.8063C13.5102 6.53442 13.5102 6.08442 13.782 5.81255C14.0539 5.54067 14.5039 5.54067 14.7758 5.81255L20.4664 11.5032C20.7383 11.775 20.7383 12.225 20.4664 12.4969L14.7758 18.1875C14.6352 18.3282 14.457 18.3938 14.2789 18.3938Z"
                      fill="#0F172A"
                    />
                    <path
                      d="M19.8094 12.7031H4.03125C3.64688 12.7031 3.32812 12.3844 3.32812 12C3.32812 11.6156 3.64688 11.2969 4.03125 11.2969H19.8094C20.1938 11.2969 20.5125 11.6156 20.5125 12C20.5125 12.3844 20.1938 12.7031 19.8094 12.7031Z"
                      fill="#0F172A"
                    />
                  </svg>
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DelegateList;
