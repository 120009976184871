import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';

const DelegateAnalytics = ({allDelegates}) => {
  const [dropdown, setDropdown] = useState('All');
  const [males, setMales] = useState([]);
  const [females, setFemales] = useState([]);
  const [dawah, setDawah] = useState([]);
  const [upper, setUpper] = useState([]);
  const [lower, setLower] = useState([]);
  const [canAnimate, setCanAnimate] = useState(false);
  const [intermediate, setIntermediate] = useState([]);

  const areaCouncil = localStorage.getItem('areaCouncil');

  
  useEffect(() => {
    if (allDelegates.length) {
      setFemales(() => allDelegates.filter((user) => user.gender === 'female'));
      setMales(() => allDelegates.filter((user) => user.gender === 'male'));
      setDawah(allDelegates.filter((user) => user.category === 'DAWAH CLASS'));
      setUpper(allDelegates.filter((user) => user.category === 'UPPER CLASS'));
      setIntermediate(allDelegates.filter((user) => user.category === 'INTERMEDIATE CLASS'));
      setLower(allDelegates.filter((user) => user.category === 'LOWER CLASS'));
      setCanAnimate(true);
    }
  }, [allDelegates]);


  const getPercentage = (totalData, categoryTotal) => {
    let percentage = (categoryTotal / totalData) * 100;
    return Math.round(percentage);
  };

  let dawahPercentage = getPercentage(allDelegates.length, dawah.length);
  let upperPercentage = getPercentage(allDelegates.length, upper.length);
  let lowerPercentage = getPercentage(allDelegates.length, lower.length);
  let intermediatePercentage = getPercentage(allDelegates.length, intermediate.length);

  let data;
  if (dropdown === 'Male') {
    data = males;
  } else if (dropdown === 'Female') {
    data = females;
  } else {
    data = allDelegates;
  }

  let dawahOptionPercentage = getPercentage(allDelegates.length, data.length);
  let upperOptionPercentage = getPercentage(data.length, upper.length);
  let lowerOptionPercentage = getPercentage(data.length, lower.length);
  let intermediateOptionPercentage = getPercentage(data.length, intermediate.length);

  let dawahDefaultPercentage = dropdown === 'All' ? dawahPercentage : dawahOptionPercentage;
  let upperDefaultPercentage = dropdown === 'All' ? upperPercentage : upperOptionPercentage;
  let lowerDefaultPercentage = dropdown === 'All' ? lowerPercentage : lowerOptionPercentage;
  let intermediateDefaultPercentage = dropdown === 'All' ? intermediatePercentage : intermediateOptionPercentage;

  return (
    <div className="space-y-6 py-4 xl:py-16 lg:py-8 w-full">
      <p className="text-xl text-[#060628] font-medium">{areaCouncil}</p>
      <div className="lg:flex lg:space-x-6 w-full space-y-8 lg:space-y-0">
        <div className="space-y-4 md:space-y-0 w-full md:w-fit lg:space-y-4 space-x-0 md:space-x-4 lg:space-x-0 mx-auto lg:mx-0 md:grid md:grid-cols-3 lg:block">
          <div className="border-t-2 border-t-[#0D5239] rounded-b-lg border-x border-x-[#D7DCE0] border-b border-b-[#D7DCE0] p-4 space-y-4">
            <p className="text-sm">TOTAL REGISTERED MEMBER</p>
            <p className="text-lg font-bold">{allDelegates.length}</p>
          </div>
          <div className="border-t-2 border-t-[#0D5239] rounded-b-lg border-x border-x-[#D7DCE0] border-b border-b-[#D7DCE0] p-4 space-y-4">
            <p className="text-sm">REGISTERED MALE</p>
            <p className="text-lg font-bold">{males.length}</p>
          </div>
          <div className="border-t-2 border-t-[#0D5239] rounded-b-lg border-x border-x-[#D7DCE0] border-b border-b-[#D7DCE0] p-4 space-y-4">
            <p className="text-sm">REGISTERED FEMALE</p>
            <p className="text-lg font-bold">{females.length}</p>
          </div>
        </div>
        <div className="md:p-8 p-4 border border-[#D7DCE0] rounded-lg space-y-8 lg:w-[71%] xl:w-[77%]">
          <div className="flex items-center justify-between">
            <p className="text-[#0D0F11] md:text-base text-sm font-medium">Breakdown for All Categories</p>
          </div>
          <div className="w-full space-y-8">
            <div className="flex space-x-4 items-center">
              <p className="text-xs text-[#24292E] w-[100px]">Dawah</p>
              <div className="w-full bg-gray-200 rounded-r-lg h-5">
                <motion.div
                  initial={{ width: 0 }}
                  animate={{ width: canAnimate ? `${dawahDefaultPercentage}%` : 0 }}
                  transition={{ ease: 'easeOut', duration: 2 }}
                  className="bg-[#0D5239] h-5 rounded-r-lg"
                ></motion.div>
              </div>
              <p className="text-sm font-bold">{dawahDefaultPercentage || 0}%</p>
            </div>
            <div className="flex space-x-4 items-center">
              <p className="text-xs text-[#24292E] w-[100px]">Upper</p>
              <div className="w-full bg-gray-200 rounded-r-lg h-5">
                <motion.div
                  initial={{ width: 0 }}
                  animate={{ width: canAnimate ? `${upperDefaultPercentage}%` : 0 }}
                  transition={{ ease: 'easeOut', duration: 2 }}
                  className="bg-[#0D5239] h-5 rounded-r-lg"
                ></motion.div>
              </div>
              <p className="text-sm font-bold">{upperDefaultPercentage || 0}%</p>
            </div>
            <div className="flex space-x-4 items-center">
              <p className="text-xs text-[#24292E] w-[100px]">Intermediate</p>
              <div className="w-full bg-gray-200 rounded-r-lg h-5">
              <motion.div
                  initial={{ width: 0 }}
                  animate={{ width: canAnimate ? `${intermediateDefaultPercentage}%` : 0 }}
                  transition={{ ease: 'easeOut', duration: 2 }}
                  className="bg-[#0D5239] h-5 rounded-r-lg"
                ></motion.div>
              </div>
              <p className="text-sm font-bold">{intermediateDefaultPercentage || 0}%</p>
            </div>
            <div className="flex space-x-4 items-center">
              <p className="text-xs text-[#24292E] w-[100px]">Lower</p>
              <div className="w-full bg-gray-200 rounded-r-lg h-5">
                {/* <SkeletonTheme baseColor="#202020" highlightColor="#444">
                  <p>
                    <Skeleton count={3} />
                  </p>
                </SkeletonTheme> */}
                <motion.div
                  initial={{ width: 0 }}
                  animate={{ width: canAnimate ? `${lowerDefaultPercentage}%` : 0 }}
                  transition={{ ease: 'easeOut', duration: 2 }}
                  className="bg-[#0D5239] h-5 rounded-r-lg"
                ></motion.div>
              </div>
              <p className="text-sm font-bold">{lowerDefaultPercentage || 0}%</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DelegateAnalytics;
