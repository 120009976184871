const analytics = () => {
    return (
        <div className="bg-[#0D5239] text-center md:p-8 p-4 md:space-y-6 space-y-4 w-full text-sm">
            <div className="md:space-y-4 space-y-2 text-white md:w-[714px] mx-auto">
                <p>CONTACT THE WEBMASTER</p>
                <p>For enquires, contact the webmaster at: send a whatsapp message to or call 08138748944, 08026944449 or send mail to webmaster@mssnlagos.net</p>
            </div>
            <p className="text-white">2022 © DESIGNED BY WEBMASTER MSSN WEBSITE</p>
        </div>
    )
}

export default analytics